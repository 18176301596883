import {Environment, IGrievanceApiData} from '../types/Misc.type';
import {useEffect, useState} from "react";
import {IAppVersionData, IDeviceArchitechture} from '../types/common';
import axios, {AxiosResponse, InternalAxiosRequestConfig} from 'axios'
import {
    getCountryFromLocale,
    getHostName,
    getLanguageFromLocale,
    getUrlWithScheme,
    smsVariablesDefaultValues
} from "../utils/constants/PagesConstants";
import {getWebsiteEnvironment, isDevelopmentEnv} from "../utils/constants/EnvironmentConstants";
import Analytics from "../analytics/Analytics";
import {getSMSApiSuccessEvent, SMSApiFailEvent} from "../analytics/AnalyticsEvents";
import {ISMSVariablesTemplateData} from '../types/components';
import {ERROR_MSG, SECURITY_HEADERS, SESSION_TOKENS} from '../utils/constants/GlobalConstants';
import {LANGUAGE_COUNTRY} from '../utils/constants/LocaleAndCountryConstants';

const websiteEnv: Environment = getWebsiteEnvironment();

const baseApiUrl: string = websiteEnv.baseApiUrl;
const langEnv: string = websiteEnv.langEnv;
const baseFolder: string = websiteEnv.baseFolder;
const baseUrl: string = getUrlWithScheme(websiteEnv.baseUrl);

const axiosInternalInstance = axios.create({
    baseURL: baseUrl
})

const authenticationAxiosInstance = axios.create({
    baseURL: baseUrl,
    headers: SECURITY_HEADERS
})

export const useAuthenticationInterceptor = () => {
    const [loadingCounter, setLoadingCounter] = useState(0);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        const changeCounter = (x) => {
            setLoadingCounter(prev => prev + x);
        }
        const handleRequest = (config: InternalAxiosRequestConfig) => {
            changeCounter(1);
            return config;
        }

        const handleResponse = (response: AxiosResponse) => {
            changeCounter(-1);
            return response;
        }

        const handleError = (error: any) => {
            changeCounter(-1);
            if (error.response && error.response.status === 401) {
                sessionStorage.removeItem(SESSION_TOKENS.SUPERSTAR_LOGIN_SUCCESS);
                sessionStorage.removeItem(SESSION_TOKENS.SUPERSTAR_USER_REGISTERED);
                error.message = "User Authentication failed";
            }
            setError(error);
            return Promise.reject(error);
        }

        const reqInterceptor = authenticationAxiosInstance.interceptors.request.use(handleRequest, handleError);
        // add response interceptors
        const resInterceptor = authenticationAxiosInstance.interceptors.response.use(handleResponse, handleError);
        return () => {
            authenticationAxiosInstance.interceptors.request.eject(reqInterceptor);
            authenticationAxiosInstance.interceptors.response.eject(resInterceptor);
        }
    }, [])

    return {loadingCounter, error, setError};
}

//// new fetching Config function
export const fetchVersionConfig = async (
    country: string
): Promise<IAppVersionData> => {
    let versionPath: string = `/${country}`;
    const versionFolder: string = isDevelopmentEnv
        ? "/app_version_stag"
        : "/app_version";
    let url: string = `${baseApiUrl}${baseFolder}${versionFolder}${versionPath}/version-config.json`;
    return apiCall<IAppVersionData>(url);
};
export const fetchDeviceArchitechtureMapping = async (): Promise<IDeviceArchitechture> => {
    const architectureFolder = `/${langEnv}/utils`;
    let url: string = `${baseApiUrl}${baseFolder}${architectureFolder}/device&architecture.json`;
    return apiCall(url);
};

export const fetchAndroidHowTo = <T>(page?: string): Promise<T> => {
    const url = baseApiUrl + "website/cms/general-configs/android_howto/" + page + ".json";
    return apiCall<T>(url);
}

export const sendSMS = (number: string, countryCode, setSmsSentMsg: any, alertMsg: string, smsVariablesTemplate: ISMSVariablesTemplateData): void => {

    if (!smsVariablesTemplate) {
        smsVariablesTemplate = smsVariablesDefaultValues;
    }

    const params = {
        "templateType": smsVariablesTemplate.templateType,
        "countryCode": countryCode,
        "phoneNumber": number
    }

    const url = "https://" + getHostName() + "/sendSms";

    axios.post(url, params)
        .then(response => {
            setSmsSentMsg(alertMsg);
            Analytics.getInstance().logEvent(getSMSApiSuccessEvent(number));
        })
        .catch(error => {
            setSmsSentMsg(ERROR_MSG);
            Analytics.getInstance().logEvent(SMSApiFailEvent);
        })
}

export const sendContact = (name: string, subject: string, number: string, query: string, email: string, setShow: any) => {

    const params = {
        "name": name,
        "subject": subject,
        "number": number,
        "query": query,
        "email": email
    }

    const url = "https://" + getHostName() + "/contact-us-api";

    axios.post(url, params)
        .then((response: AxiosResponse<any>) => {
            console.log("SEND Data", response)
            if (response.status === 200)
                setShow(true);
        })
        .catch((e) => {
            console.log("SEND E", e)
            setShow(false);
        })
}

export const getMatchListData = async <T>(lang: string = "en", sportId: number, locale: string): Promise<T> => {
    return await internalApiCall(`zo-app/match-list?sportId=${sportId}`, lang, locale);
}

export const getMatchReportData = async <T>(matchId: string, lang: string, locale: string): Promise<T> => {
    return await internalApiCall(`zo-app/match-report?matchId=${matchId}`, lang, locale);
}

export const postToInternalApi = async (url: string, headers, data) => {
    return authenticationAxiosInstance.post(url, data, {
        headers: headers,
        withCredentials: true
    }).then(response => {
        return response.data
    }).catch((error) => {
        throw error;
    });
}

export const getTestingData = async <T>(jsonName: string): Promise<T> => {
    const url = `${baseApiUrl}${baseFolder}/${langEnv}/testing/${jsonName}.json`;
    return await apiCall<T>(url);
}

export const getData = async <T>(locale: string = LANGUAGE_COUNTRY.EN_IN, pageName: string, forceApiCall: boolean = false): Promise<T> => {
    const cmsUrl = `${baseApiUrl}${baseFolder}/cms/${langEnv}/2/${getCountryFromLocale(locale)}/${getLanguageFromLocale(locale)}/${pageName}.json`
    return await apiCall<T>(cmsUrl)
}

export const fetchUtilsData = async <T>(pageName: string): Promise<T> => {
    let url: string = `${baseApiUrl}${baseFolder}/${langEnv}/utils/${pageName}.json`;
    return await apiCall<T>(url)
}

const apiCall = async <T>(url: string): Promise<T> => {
    return fetch(url, {cache: "no-cache"})
        .then(response => {
            return response.json() as Promise<T>
        })
        .catch((error) => {
            const errorMessage = {
                message: error.toString(),
                url: url,
                time: new Date().toISOString()
            }
            throw new Error(JSON.stringify(errorMessage));
        })
}

export const serverCheckForAppDown = async (): Promise<Response> => {
    let url: string = isDevelopmentEnv ? `https://stg.winzogames.com/time` : `https://app.winzogames.com/time`;
    return fetch(url, {cache: "no-cache"}).then(response => {
        return response
    }).catch((error) => {
        const errorMessage = {
            message: error.toString(),
            url: url,
            time: new Date().toISOString()
        }
        return Response.json(errorMessage, {
            status: 500
        })
    })
}

const internalApiCall = async <T>(url: string, lang: string, locale: string): Promise<T> => {
    return axiosInternalInstance.get(url, {
        headers: {
            languageId: lang,
            locale
        }
    }).then(response => {
        return response.data
    }).catch((error) => {
        const errorMessage = {
            message: error.toString(),
            url: axiosInternalInstance.defaults.baseURL + url,
            time: new Date().toISOString()
        }
        // ZOLogger.error({
        //     message: error.toString(),
        //     pageName: axiosInternalInstance.defaults.baseURL + url,
        //     params: {},
        //     errorType: "Internal Api Fetch Error"
        // });
        throw new Error(JSON.stringify(errorMessage));
    });
}

export const postLogglyLogs = (path: string, data: string) => {
    axios.post(path, data, {
            headers: {
                'Content-Type': 'text/plain'
            }
        }
    ).catch((error) => {
        const errorMessage = {
            message: error.toString(),
            time: new Date().toISOString()
        }
        throw new Error(JSON.stringify(errorMessage))
    })
}

export const uploadFile = (path: string, data: FormData) => {
    return axiosInternalInstance.post(path, data).catch((error) => {
        throw new Error(error.response.data.message);
    });
};

export const submitGrievanceForm = (path: string, data: IGrievanceApiData) => {
    return axiosInternalInstance.post(path, {form: data}).catch((error) => {
        throw new Error(error.response.data.message);
    });
};
  